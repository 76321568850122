import cx from 'classnames'
import * as Toast from '@radix-ui/react-toast'
import { useAtom } from 'jotai'
import { FC, SVGAttributes } from 'react'

import { atom } from 'jotai'

type Toast = {
  show: boolean
  icon?: FC<SVGAttributes<SVGElement>> | null
  message: string
}

const toastAtom = atom<Toast>({
  show: false,
  message: '',
  icon: null,
})

const toastTimeoutAtom = atom<ReturnType<typeof setTimeout> | null>(null)

export const useToast = () => {
  const [toast, updateToast] = useAtom(toastAtom)
  const [toastTimeout, updateToastTimeout] = useAtom(toastTimeoutAtom)

  const clearToast = () => {
    updateToast({ ...toast, show: false })
  }

  const setToast = ({ message, icon = null }: Omit<Toast, 'show'>) => {
    clearToast()
    if (toastTimeout) {
      clearTimeout(toastTimeout)
    }

    const newTimeout = setTimeout(() => {
      updateToast({ message, icon, show: true })
    }, 100)

    updateToastTimeout(newTimeout)
  }

  return {
    toast,
    setToast,
    clearToast,
  }
}

const ToastNotification = ({
  siteBannerShowing,
}: {
  siteBannerShowing: boolean
}) => {
  const { toast, clearToast } = useToast()
  const ToastIcon = toast?.icon

  return (
    <Toast.Provider>
      <Toast.Root
        className={cx(
          'toast rounded-md border border-teal-3 bg-teal-1 px-24 py-16 [box-shadow:0_0_14px_rgba(11,131,129,0.3)]',
          'mobile-nav:fixed mobile-nav:left-24 mobile-nav:right-24 mobile-nav:mt-12',
          'desktop-nav:float-right desktop-nav:mr-24 desktop-nav:mt-16',
          'lg:mr-48',
          {
            'mobile-nav:top-site-banner': siteBannerShowing,
            'mobile-nav:top-0': !siteBannerShowing,
          }
        )}
        open={toast?.show}
        onOpenChange={clearToast}
      >
        <Toast.Description className="flex items-center font-semibold">
          {toast ? (
            <>
              {ToastIcon ? (
                <ToastIcon className="mr-8 stroke-current rect-24" />
              ) : null}
              {toast.message}
            </>
          ) : null}
        </Toast.Description>
      </Toast.Root>

      <Toast.Viewport />
    </Toast.Provider>
  )
}

export default ToastNotification
