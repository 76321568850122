import { FC } from 'react'
import cx from 'classnames'

const Banner: FC<{ className?: string; error?: boolean }> = ({
  children,
  className,
  error,
}) => {
  return (
    <div
      className={cx(className, 'py-14', {
        'bg-error-2': error,
        'bg-teal-1': !error,
      })}
    >
      <p
        className={cx('mx-auto max-w-1440 px-24 text-14 tracking-1 lg:px-48', {
          'text-error-1': error,
        })}
      >
        {children}
      </p>
    </div>
  )
}

export default Banner
