import React, { FC, MouseEvent } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'

type LinkNavProps = {
  href: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
}

const LinkNav: FC<LinkNavProps> = ({ href, children, onClick }) => {
  const { asPath } = useRouter()
  const pathWithoutParams = asPath.includes('?')
    ? asPath.slice(0, asPath.indexOf('?'))
    : asPath
  const selected = pathWithoutParams === href

  return (
    <li className="relative">
      <Link href={href}>
        <a onClick={onClick} className="relative block py-18 text-web-darkest">
          {children}
        </a>
      </Link>
      {selected ? (
        <motion.div
          layoutId="currentLink"
          layoutDependency={asPath}
          className="absolute inset-x-0 bottom-0 h-2 bg-teal-3"
        />
      ) : null}
    </li>
  )
}

export default LinkNav
