import { API } from '@aws-amplify/api'
import { useQuery } from 'react-query'
import * as Sentry from '@sentry/nextjs'

export type HomeOfferStepKey =
  | 'Options'
  | 'Terms'
  | 'Maintenance'
  | 'Alterations'
  | 'UsageInsurance'
  | 'EndOfTerm'

export type HomeOfferStep = {
  key: HomeOfferStepKey
  completedAt: string | null
  acknowledgements: string[]
  updateNeeded: boolean
  required: boolean
}

export enum HomeOfferProduct {
  saver = 'saver',
  boost = 'boost',
}

export type HomeOffer = {
  id: number
  status: 'unsubmitted' | 'open' | 'won' | 'lost' | 'closed'
  listing: {
    id: number
    address: string
    beds: number
    baths: number
    sqft: number
    thumbnail: string
    listPrice: number
    estimatedSalePrice: number
  }
  steps: HomeOfferStep[]
  paymentOptions: {
    valueShare: number | null
    monthlyPayment: number | null
    productType: HomeOfferProduct | null
  }
}

const getOffer = async (id: number): Promise<HomeOffer> => {
  try {
    const res = await API.get('offer', `/offers/${id}/status`, {})

    return res.offer
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    Sentry.captureException(error)
    throw new Error(error.response?.data?.error || 'Something went wrong')
  }
}

export const useOffer = (
  id?: number | null,
  onSuccess?: (data: HomeOffer) => void,
  queryKey = 'offer'
) => {
  return useQuery(
    [queryKey, id],
    () => {
      if (typeof id === 'number') {
        return getOffer(id)
      }
    },
    {
      enabled: !!id,
      onSuccess,
    }
  )
}
