import { FC, SVGAttributes } from 'react'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'

import { useTracking } from '../../services/context/TrackingContext'

const MobileNavItem = ({
  text,
  url,
  icon: Icon,
  iconClass,
  iconSelectedClass,
  trackingEvent,
  trackingPriority,
}: {
  text: string
  url: string
  icon: FC<SVGAttributes<SVGElement>>
  iconClass?: string
  iconSelectedClass?: string
  trackingEvent?: string
  trackingPriority?: number
}) => {
  const { track } = useTracking()
  const { asPath } = useRouter()
  const pathWithoutParams = asPath.includes('?')
    ? asPath.slice(0, asPath.indexOf('?'))
    : asPath
  const selected = pathWithoutParams === url

  return (
    <li className="relative">
      <Link href={url}>
        <a
          className={cx('grid h-full place-items-center util-caption', {
            'text-grey-7': !selected,
          })}
          onClick={
            trackingEvent
              ? () =>
                  track({
                    event: trackingEvent,
                    properties: {
                      link_type: 'Text',
                      link_location: 'Nav',
                      link_text: text,
                      link_position: 'Left',
                      link_priority: trackingPriority,
                    },
                  })
              : undefined
          }
        >
          <div className="space-y-8">
            <Icon
              className={cx('mx-auto rect-24', {
                'stroke-grey-7': !selected && !iconClass,
                [iconClass ?? '']: !selected && iconClass,
                'fill-grey-8 stroke-grey-8 text-white':
                  selected && !iconSelectedClass,
                [iconSelectedClass ?? '']: selected && iconSelectedClass,
              })}
            />
            <span
              className={cx('block', {
                'font-semibold': selected,
              })}
            >
              {text}
            </span>
          </div>
        </a>
      </Link>
      {selected ? (
        <motion.div
          layoutId="currentMobileLink"
          layoutDependency={asPath}
          className="absolute inset-x-0 -top-px h-[3px] bg-teal-3"
        />
      ) : null}
    </li>
  )
}

export default MobileNavItem
