const SkipToContent = () => {
  return (
    <a
      className="top-8 left-8 z-modal rounded-md bg-white font-semibold ring sr-only focus:sr-undo-absolute"
      href="#content"
    >
      <span className="block px-16 py-8">Skip to content</span>
    </a>
  )
}

export default SkipToContent
