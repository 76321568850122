import { FC, useState } from 'react'
import cx from 'classnames'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { UserIcon } from 'icons'

import { useTracking } from '../../services/context/TrackingContext'

interface IDropdownProps {
  onLogout: () => void
  profilePicture: string | null
}

const AccountDropdown: FC<IDropdownProps> = (props) => {
  const { t } = useTranslation('common')
  const { track } = useTracking()
  const { onLogout, profilePicture } = props
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu.Root
      open={open}
      onOpenChange={() => setOpen(!open)}
      modal={false}
    >
      <DropdownMenu.Trigger asChild>
        {profilePicture ? (
          <button
            className={cx(
              'relative shrink-0 rounded-full after:absolute after:inset-0 after:rounded-full after:transition hover:after:bg-black hover:after:bg-opacity-20',
              {
                'after:bg-black after:bg-opacity-20': open,
              }
            )}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              className="h-40 w-40 rounded-full"
              width="40"
              height="40"
              src={profilePicture}
              alt=""
            />
            <span className="sr-only">My Account</span>
          </button>
        ) : (
          <button
            className={cx(
              'rounded-full border p-8 transition-colors duration-200 hover:border-teal-3 hover:bg-teal-1',
              {
                'border-grey-3 bg-white': !open,
                'border-teal-3 bg-teal-1': open,
              }
            )}
          >
            <span className="sr-only">My Account</span>
            <UserIcon className="stroke-grey-8 rect-22" />
          </button>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="end"
        className="mt-18 rounded-md  border border-green-1 bg-white py-8 shadow-md"
      >
        <ul className="body">
          <li>
            <Link href="/account" passHref>
              <DropdownMenu.Item asChild onSelect={(e) => e.preventDefault()}>
                <a
                  className="block px-16 py-8 hover:cursor-pointer hover:text-teal-4"
                  onClick={() =>
                    track({
                      event: 'Account Page Opened',
                      properties: {
                        link_type: 'Text',
                        link_location: 'Nav',
                        link_text: t('navbar.my-account'),
                        link_position: 'Right - Dropdown',
                        link_priority: 1,
                      },
                    })
                  }
                >
                  {t('navbar.my-account')}
                </a>
              </DropdownMenu.Item>
            </Link>
          </li>
          <li>
            <DropdownMenu.Item
              onClick={onLogout}
              className="px-16 py-8 hover:cursor-pointer hover:text-teal-4"
            >
              {t('navbar.signout')}
            </DropdownMenu.Item>
          </li>
        </ul>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

export default AccountDropdown
