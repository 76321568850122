import { FC, useState } from 'react'
import Link from 'next/link'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useTranslation } from 'next-i18next'

import { INavbarLink } from '.'

import { ChevronIcon } from 'icons'

import { useTracking } from '../../services/context/TrackingContext'

const MoreLinksDropdown: FC<{ links: INavbarLink[] }> = ({ links }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('common')
  const { track } = useTracking()

  return (
    <DropdownMenu.Root
      open={open}
      onOpenChange={() => setOpen(!open)}
      modal={false}
    >
      <DropdownMenu.Trigger asChild>
        <button className="group flex items-center space-x-2">
          <span>{t('navbar.more')}</span>
          <ChevronIcon className="h-16 w-16 stroke-current transition-transform duration-150 group-state-open:rotate-180" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="start"
        className="mt-10 w-[160px] rounded-md border border-green-1 bg-white py-8 shadow-md"
      >
        <ul className="body">
          {links.map(({ text, url, event }, i) => (
            <li key={url}>
              <Link href={url} passHref>
                <DropdownMenu.Item asChild>
                  <a
                    className="block px-16 py-8 hover:cursor-pointer hover:text-teal-4"
                    onClick={() => {
                      setOpen(false)
                      track({
                        event,
                        properties: {
                          link_type: 'Text',
                          link_location: 'Nav',
                          link_text: text,
                          link_position: 'Dropdown',
                          link_priority: i + 1,
                        },
                      })
                    }}
                  >
                    {text}
                  </a>
                </DropdownMenu.Item>
              </Link>
            </li>
          ))}
        </ul>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

export default MoreLinksDropdown
