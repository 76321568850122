import { FC, useState } from 'react'
import Link from 'next/link'
import cx from 'classnames'
import { useTranslation } from 'next-i18next'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

import { LoadingSpinner, Input } from 'ui'

import { useCustomer } from '../services/customer/loadAttributes'

import { InstagramIcon, LinkedInIcon } from 'icons'
import useAuth from '../hooks/useAuth'
import { useTracking } from '../services/context/TrackingContext'
import { useMediaQuery } from 'usehooks-ts'

const FooterHeading: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <h3 className={cx(className, 'pt-14 pb-12 text-14 font-bold text-white')}>
      {children}
    </h3>
  )
}

const FooterList: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <ul
      className={cx(
        className,
        'space-y-10 border-t border-teal-4 pt-10 pb-24 md:pb-32'
      )}
    >
      {children}
    </ul>
  )
}

const FooterListItem: FC<{
  href?: string
  className?: string
  label?: string
  trackingPosition: string
  trackingPriority: number
}> = ({
  children,
  className,
  href,
  label,
  trackingPosition,
  trackingPriority,
}) => {
  const { track } = useTracking()

  return (
    <li
      className={cx({
        'flex items-center': label,
      })}
    >
      {!href ? (
        <span className={cx(className, 'text-14 text-[#EBEAE7] opacity-70')}>
          {children}
        </span>
      ) : (
        <Link href={href} passHref>
          <a
            className={cx(className, 'block text-14 text-white underline')}
            onClick={() => {
              track({
                event: 'CTA Clicked',
                properties: {
                  link_type: 'Text',
                  link_location: 'Footer',
                  link_text: children,
                  link_position: trackingPosition,
                  link_priority: trackingPriority + 1,
                },
              })
            }}
          >
            {children}
          </a>
        </Link>
      )}
      {label ? (
        <span className="ml-16 block rounded-full bg-gold-1 px-8 py-4 text-8 font-bold uppercase tracking-1 text-teal-5">
          {label}
        </span>
      ) : null}
    </li>
  )
}

const FooterSubscribe = ({ tagline }: { tagline: string }) => {
  const { t } = useTranslation('common')
  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const { track, identify } = useTracking()
  const { data: customer } = useCustomer()

  return (
    <div className="mx-auto max-w-1440 space-y-14 px-24 py-14 lg:flex lg:items-center lg:justify-between lg:space-y-0 lg:space-x-16 lg:px-48 lg-d:space-y-16">
      <p className="text-16 text-white lg:text-18 lg-d:text-center">
        {tagline}
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <fieldset
          disabled={submitting}
          className="flex flex-col items-center md:flex-row md:space-x-10 lg-d:w-full lg-d:justify-center"
        >
          {!submitted ? (
            <Input
              label={t('footer.newsletter-label')}
              hideLabel
              id="newsletter"
              name="email"
              value={email}
              type="email"
              placeholder={t('footer.newsletter-label')}
              className="w-[240px] text-center md:text-left md:min-w-[263px] md:w-auto"
              onChange={(e) => setEmail(e.currentTarget.value)}
              required
            />
          ) : null}

          <button
            onClick={() => {
              setSubmitting(true)
              if (!customer) {
                identify({
                  userId: email,
                  traits: {
                    email,
                  },
                })
              }
              track({
                event: 'Newsletter Subscription Form Submitted',
                properties: {
                  email,
                },
              })
              setSubmitting(false)
              setSubmitted(true)
            }}
            type="submit"
            className={cx(
              'w-[240px] mt-16 md:w-auto md:mt-0 rounded-md px-[22px] py-8 leading-snug transition-colors',
              'border border-teal-5 bg-teal-5 text-white',
              'hover:border-teal-5 hover:bg-teal-5 focus:border-teal-5 focus:bg-teal-5',
              'disabled:border-transparent disabled:bg-grey-3 disabled:text-grey-6',
              {
                relative: submitted,
              }
            )}
          >
            {submitting ? <LoadingSpinner /> : null}
            <span
              className={cx('inline-block min-w-[90px] transition-opacity', {
                'opacity-0': submitting,
              })}
            >
              {submitted
                ? t('footer.newsletter-btn-success')
                : t('footer.newsletter-btn')}
            </span>
          </button>
        </fieldset>
      </form>
    </div>
  )
}

export interface IFooterLink {
  key: string
  value: string
}

export interface IFooterProps {
  footerSiteLinks?: IFooterLink[]
  footerCurrentLocations?: IFooterLink[]
  footerFutureLocations?: string[]
  footerAdditionalLinksHeading?: string
  footerAdditionalLinks?: IFooterLink[]
  footerConnectText?: {
    json: Document
  }
}

const Footer = ({
  footerSiteLinks,
  footerCurrentLocations,
  footerFutureLocations,
  footerAdditionalLinksHeading,
  footerAdditionalLinks,
  footerConnectText,
}: IFooterProps) => {
  const { t } = useTranslation('common')
  const { isAuthenticated } = useAuth()
  const { data: customer } = useCustomer()

  const isDesktop = useMediaQuery('(min-width: 48em)') // i.e. md

  const showAdditionalLinks =
    isAuthenticated &&
    footerAdditionalLinks?.length &&
    (!customer?.approval.status ||
      customer?.approval.status === 'created' ||
      customer?.approval.status === 'failed')

  return (
    <footer className="pb-mobile-nav desktop-nav:pb-0">
      <div className="bg-teal-4">
        <FooterSubscribe tagline={t('footer.idx-disclaimer')} />
      </div>
      <nav className="overflow-hidden bg-teal-5">
        <div className="md:mx-auto md:flex md:max-w-1440 md:divide-x md:divide-teal-4 md:px-48">
          {footerSiteLinks?.length ? (
            <div>
              <FooterHeading className="relative px-24 md:pl-0 md:pr-120">
                Acre
              </FooterHeading>
              <FooterList
                className={cx(
                  'relative px-24 md:pl-0 md:pr-32',
                  'before:absolute before:right-full before:-top-px before:block before:w-screen before:border-b before:border-teal-4'
                )}
              >
                {footerSiteLinks.map(({ key: text, value: url }, index) => (
                  <FooterListItem
                    key={text}
                    href={url}
                    label={
                      text === 'Careers' ? t('footer.we-are-hiring') : undefined
                    }
                    trackingPosition={isDesktop ? 'Left' : 'Middle'}
                    trackingPriority={index}
                  >
                    {text}
                  </FooterListItem>
                ))}
              </FooterList>
            </div>
          ) : null}
          {footerCurrentLocations?.length || footerFutureLocations?.length ? (
            <div>
              <FooterHeading className="px-24 md:pl-32 md:pr-120">
                {t('footer.homes')}
              </FooterHeading>
              <FooterList
                className={cx('px-24 md:px-32', {
                  'relative before:absolute before:left-full before:-top-px before:block before:w-screen before:border-b before:border-teal-4':
                    !showAdditionalLinks,
                })}
              >
                {footerCurrentLocations?.map(
                  ({ key: text, value: url }, index) => (
                    <FooterListItem
                      key={text}
                      href={url}
                      trackingPosition={isDesktop ? 'Left - Middle' : 'Middle'}
                      trackingPriority={
                        isDesktop
                          ? index
                          : index + (footerSiteLinks?.length ?? 0)
                      }
                    >
                      {text}
                    </FooterListItem>
                  )
                )}
                {footerFutureLocations?.map((futureLocation, index) => (
                  <FooterListItem
                    key={futureLocation}
                    trackingPosition={isDesktop ? 'Left - Middle' : 'Middle'}
                    trackingPriority={index}
                  >
                    {futureLocation} (coming soon)
                  </FooterListItem>
                ))}
              </FooterList>
            </div>
          ) : null}
          {showAdditionalLinks ? (
            <div>
              <FooterHeading className="px-24 md:pl-32 md:pr-120">
                {footerAdditionalLinksHeading}
              </FooterHeading>
              <FooterList
                className={cx(
                  'px-24 md:px-32',
                  'relative before:absolute before:left-full before:-top-px before:block before:w-screen before:border-b before:border-teal-4'
                )}
              >
                {footerAdditionalLinks.map(
                  ({ key: text, value: url }, index) => (
                    <FooterListItem
                      key={text}
                      href={url}
                      trackingPosition={isDesktop ? 'Left - Middle' : 'Middle'}
                      trackingPriority={index}
                    >
                      {text}
                    </FooterListItem>
                  )
                )}
              </FooterList>
            </div>
          ) : null}
          <div>
            <FooterHeading className="px-24 md:pl-32 md:pr-120">
              Connect
            </FooterHeading>
            <div
              className={cx(
                'px-24 pt-10 text-14 leading-relaxed text-white rt-a:underline md:px-32',
                'border-t border-teal-4',
                'relative before:absolute before:left-full before:-top-px before:block before:w-screen before:border-b before:border-teal-4'
              )}
            >
              {footerConnectText
                ? documentToReactComponents(footerConnectText.json)
                : null}
            </div>
            <ul className="flex items-center space-x-20 px-24 pt-20 pb-24 md:px-32 md:pb-32">
              <li>
                <a
                  className="block text-white"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/acre.homes/"
                >
                  <InstagramIcon
                    aria-hidden="true"
                    className="rect-footer-social"
                  />
                  <span className="sr-only">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  className="block text-white"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/acrehomes2021/"
                >
                  <LinkedInIcon
                    aria-hidden="true"
                    className="rect-footer-social"
                  />
                  <span className="sr-only">LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </footer>
  )
}

export default Footer
