import Head from 'next/head'
import { useRouter } from 'next/router'

const BASE_TITLE = 'Acre Homes'
const DEFAULT_DESCRIPTION = 'Fundamentally redesigning homeownership'
const DEFAULT_IMAGE = '/website-assets/share.png'
const BASE_URL = 'https://acrehomes.com'

const Meta = ({
  seoTitle = '',
  seoDescription = DEFAULT_DESCRIPTION,
  seoImage = `${BASE_URL}${DEFAULT_IMAGE}`,
}: {
  seoTitle?: string
  seoDescription?: string
  seoImage?: string
}) => {
  const title = [seoTitle, BASE_TITLE].filter((v) => v).join(' | ')
  const router = useRouter()
  const url = `${BASE_URL}${router.asPath.split('?')[0].split('#')[0]}`

  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={seoDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={seoImage} />

      <meta name="viewport" content="initial-scale=1, width=device-width" />
    </Head>
  )
}

export default Meta
