import { FC, useRef, useState } from 'react'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import * as Dialog from '@radix-ui/react-dialog'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import { useIntersectionObserver } from 'usehooks-ts'
import { useAtom } from 'jotai'

import LinkNav from './LinkNav'
import AccountDropdown from './AccountDropdown'
import Banner from './Banner'
import MoreLinksDropdown from './MoreLinksDropdown'
import MobileNavItem from './MobileNavItem'
import ToastNotification from './Toast'

import {
  PrimaryButton,
  PrimaryButtonLink,
  SecondaryButton,
  SecondaryButtonLink,
  TanButton,
} from 'ui'
import { zendeskAPI } from '../ChatLauncher'

import { useAppState } from '../../services/context/AppContext'
import useAuth from '../../hooks/useAuth'
import {
  CustomerAttribute,
  useCustomer,
} from '../../services/customer/loadAttributes'
import { useTracking } from '../../services/context/TrackingContext'
import { HomeOffer, useOffer } from '../../services/offer/status'

import { numberToCurrency } from 'utils'
import { MY_ACRE_ROUTE } from '../../utils/constants'
import { usePreferredHomesRoute } from '../../hooks/use-preferred-homes-route'
import { clearLocalStorage } from '../../utils/localStorage'
import {
  getApprovedRedirectPathAtom,
  customerFavoriteListings,
} from '../../utils/atoms'

import {
  AcreLogoIcon,
  MenuIcon,
  CloseIcon,
  DashboardIcon,
  CalculatorIcon,
  HomesIcon,
  NavbarHelpCircleIcon,
  UserPlusIcon,
} from 'icons'
import NavAcreIcon from '../../svgs/nav-acre.svg'

const ActionButton: FC<{
  customerApprovalStatus?: CustomerAttribute['approval']['status']
  customerOfferStatus?: HomeOffer['status']
  customerApprovedAmount: number
  className?: string
}> = ({
  customerApprovalStatus,
  customerOfferStatus,
  customerApprovedAmount,
  className,
}) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { track } = useTracking()
  const [, updateGetApprovedRedirectPathAtom] = useAtom(
    getApprovedRedirectPathAtom
  )

  const handleLogin = async () => {
    router.push('/get-approved')
  }

  if (customerApprovalStatus === 'approved') {
    return (
      <div
        className={cx(
          className,
          'flex items-center self-stretch rounded-md bg-teal-1 px-24 py-8 text-teal-5'
        )}
      >
        {t('navbar.approved-for', {
          amt: `${numberToCurrency(customerApprovedAmount)}`,
        })}
      </div>
    )
  }

  if (['open', 'won'].includes(customerOfferStatus || '')) {
    return (
      <TanButton
        className={cx(className, 'w-full whitespace-nowrap')}
        disabled
        onClick={() => null}
      >
        {t('navbar.offer-pending')}
      </TanButton>
    )
  }

  if (
    customerApprovalStatus === 'under review' ||
    customerApprovalStatus === 'pending'
  ) {
    return (
      <TanButton
        className={cx(className, 'w-full whitespace-nowrap')}
        disabled
        onClick={() => null}
      >
        {t('navbar.approval-pending')}
      </TanButton>
    )
  }

  return (
    <PrimaryButton
      className={cx(className, 'w-full whitespace-nowrap')}
      onClick={() => {
        handleLogin()
        track({
          event: 'Approval Flow Opened',
          properties: {
            link_type: 'Button',
            link_location: 'Nav',
            link_text: t('navbar.get-approved'),
            link_position: 'Right',
            link_priority: 1,
          },
        })
        updateGetApprovedRedirectPathAtom(router.asPath)
      }}
    >
      {t('navbar.get-approved')}
    </PrimaryButton>
  )
}

export const TransCompatLink: FC<{ href: string; className?: string }> = ({
  children,
  href,
  className,
}) => {
  return (
    <Link href={href}>
      <a className={cx(className, 'underline')}>{children}</a>
    </Link>
  )
}

export interface INavbarLink {
  text: string
  url: string
  event: string
}

export interface IPrimaryCta {
  text?: string
  url?: string
}

const Navbar = ({
  primaryCta,
  className,
  showBorder,
  scrollTriggerClassName,
  siteBanner,
  marketingBanner,
}: {
  primaryCta?: IPrimaryCta
  className?: string
  showBorder?: boolean
  scrollTriggerClassName?: string
  siteBanner?: {
    json: Document
  }
  marketingBanner?: {
    json: Document
  }
}) => {
  const [showMobileNav, setShowMobileNav] = useState(false)
  const router = useRouter()

  const {
    state: { errorMsg },
  } = useAppState()
  const { isAuthenticated, signOut } = useAuth()
  const { t } = useTranslation('common')
  const {
    status: customerStatus,
    data: customerData,
    remove: removeCustomerQuery,
  } = useCustomer()
  const { data: offer } = useOffer(customerData?.homeOffer?.id)
  const { track, resetTracking } = useTracking()

  const scrollTriggerRef = useRef(null)
  const entry = useIntersectionObserver(scrollTriggerRef, {})
  const showStickyNavBg = entry && !entry.isIntersecting

  const [, setCustomerFavoriteListings] = useAtom(customerFavoriteListings)

  const handleLogout = async () => {
    zendeskAPI.logout()
    removeCustomerQuery()
    resetTracking()
    clearLocalStorage()

    await signOut()

    setCustomerFavoriteListings([])
  }

  const homesRoute = usePreferredHomesRoute()

  const MyAcreLink: INavbarLink = {
    text: t('navbar.my-acre'),
    url: MY_ACRE_ROUTE,
    event: 'Dashboard Opened',
  }
  const HomesLink: INavbarLink = {
    text: t('navbar.homes'),
    url: homesRoute,
    event: 'Property Search Opened',
  }
  const CalculatorLink: INavbarLink = {
    text: t('navbar.calculator'),
    url: '/calculator',
    event: 'Property Purchase Calculator Page Opened',
  }
  const CompanyLink: INavbarLink = {
    text: t('navbar.company'),
    url: '/company',
    event: 'Company Page Opened',
  }
  const AgentsLink: INavbarLink = {
    text: t('navbar.agents'),
    url: '/agents',
    event: 'Agent Page Opened',
  }
  const FaqLink: INavbarLink = {
    text: t('navbar.faq'),
    url: '/faq',
    event: 'FAQ Page Opened',
  }
  const HowItWorksLink: INavbarLink = {
    text: t('navbar.how'),
    url: '/how-it-works',
    event: 'How It Works Page Opened',
  }
  const BlogLink: INavbarLink = {
    text: t('navbar.blog'),
    url: 'https://discover.acrehomes.com/blog',
    event: 'Blog Opened',
  }

  const unauthLinks: INavbarLink[] = [HomesLink, HowItWorksLink, CalculatorLink]
  const authLinks: INavbarLink[] = [MyAcreLink, HomesLink, HowItWorksLink]
  const authMoreLinks: INavbarLink[] = [
    CalculatorLink,
    CompanyLink,
    AgentsLink,
    FaqLink,
    BlogLink,
  ]
  const unauthMoreLinks = [FaqLink, CompanyLink, AgentsLink, BlogLink]
  const unauthMobileMoreLinks = [
    FaqLink,
    HowItWorksLink,
    CompanyLink,
    AgentsLink,
    BlogLink,
  ]
  const links = isAuthenticated ? authLinks : unauthLinks
  const moreLinks = isAuthenticated ? authMoreLinks : unauthMoreLinks
  const mobileLinks = isAuthenticated ? authMoreLinks : unauthMobileMoreLinks

  return (
    <>
      <div
        className={cx(scrollTriggerClassName, 'absolute h-px w-full')}
        ref={scrollTriggerRef}
      />
      {errorMsg ? (
        <Banner error className="bg-error-2">
          {errorMsg}
        </Banner>
      ) : null}
      {customerData?.homeOffer?.status === 'open' && offer?.listing ? (
        <Banner>
          <Trans i18nKey="offer-banner">
            Your offer for{' '}
            <TransCompatLink href={`/homes/${offer.listing.id}/details`}>
              {{ address: offer.listing.address }}
            </TransCompatLink>{' '}
            is being prepared! If you have any questions, comments or concerns
            you can reach out to an{' '}
            <a className="underline" href="mailto:support@acrehomes.com">
              Acre Advocate
            </a>{' '}
            and we will get back to you shortly.
          </Trans>
        </Banner>
      ) : marketingBanner ? (
        <Banner className="text-12 rt-a:font-semibold rt-a:text-teal-3 rt-a:underline">
          {documentToReactComponents(marketingBanner.json)}
        </Banner>
      ) : null}
      {siteBanner ? (
        <div className="fixed top-0 z-nav w-full bg-teal-1">
          <div className="mx-auto flex h-site-banner max-w-[1512px] items-center px-24 text-12 rt-a:font-semibold rt-a:text-teal-3 rt-a:underline sm:body-sm lg:px-48">
            {documentToReactComponents(siteBanner.json)}
          </div>
        </div>
      ) : null}
      <nav
        className={cx(
          className,
          'fixed bottom-0 z-nav w-full transition duration-200 desktop-nav:bottom-auto desktop-nav:top-0 desktop-nav:h-nav mobile-nav:bg-white',
          {
            'mt-site-banner': siteBanner,
            'border-b border-green-1': showBorder,
            'bg-white shadow-md': showStickyNavBg,
          }
        )}
        aria-label="Site Navigation"
      >
        <div>
          <div className="mx-auto hidden max-w-[1512px] px-24 desktop-nav:flex desktop-nav:items-center desktop-nav:justify-between lg:px-48">
            {/* Desktop Nav: Logo & Links */}
            <div className="flex">
              <Link href="/">
                <a className="flex items-center py-18">
                  <span className="sr-only">Acre</span>
                  <AcreLogoIcon
                    className="fill-tan-3 text-grey-8 rect-logo"
                    aria-hidden="true"
                  />
                </a>
              </Link>

              <ul className="ml-24 hidden space-x-16 desktop-nav:flex xl:ml-32 xl:space-x-24">
                {links.map(({ text, url, event }, i) => (
                  <LinkNav
                    key={text}
                    href={url}
                    onClick={() =>
                      track({
                        event,
                        properties: {
                          link_type: 'Text',
                          link_location: 'Nav',
                          link_text: text,
                          link_position: 'Left',
                          link_priority: i + 1,
                        },
                      })
                    }
                  >
                    {text}
                  </LinkNav>
                ))}
                <MoreLinksDropdown links={moreLinks} />
              </ul>
            </div>

            {/* Desktop Nav: Action Button & Account Dropdown */}
            {/* @TODO handle failed query status */}
            <div
              className={cx('flex items-center space-x-12 transition-opacity', {
                'opacity-0': customerStatus !== 'success',
                'opacity-100': customerStatus === 'success' || !isAuthenticated,
              })}
            >
              {!isAuthenticated ? (
                <Link href="/sign-in" passHref>
                  <SecondaryButtonLink
                    className="bg-white"
                    onClick={() =>
                      track({
                        event: 'Sign-in Flow Opened',
                        properties: {
                          link_type: 'Button',
                          link_location: 'Nav',
                          link_text: t('navbar.signin'),
                          link_position: 'Right',
                          link_priority: 2,
                        },
                      })
                    }
                  >
                    {t('navbar.signin')}
                  </SecondaryButtonLink>
                </Link>
              ) : (
                <Link href="/book" passHref>
                  <SecondaryButtonLink
                    className="bg-white"
                    onClick={() =>
                      track({
                        event: 'Book-a-Call Opened',
                        properties: {
                          link_type: 'Button',
                          link_location: 'Nav',
                          link_text: t('navbar.book-a-call'),
                          link_position: 'Right',
                          link_priority: 2,
                        },
                      })
                    }
                  >
                    {t('navbar.book-a-call')}
                  </SecondaryButtonLink>
                </Link>
              )}

              {customerData?.approval.status &&
              !(
                customerData.homeOffer?.status &&
                ['closed', 'won', 'open'].includes(
                  customerData.homeOffer?.status
                )
              ) &&
              !customerData.tenant?.property ? (
                <ActionButton
                  customerApprovalStatus={customerData?.approval.status}
                  customerApprovedAmount={
                    customerData?.approval.maxPurchasePrice || 0
                  }
                  customerOfferStatus={customerData.homeOffer?.status}
                />
              ) : null}

              {!customerData?.approval.status &&
              router.asPath !== MY_ACRE_ROUTE &&
              primaryCta?.text &&
              primaryCta?.url ? (
                <Link
                  href={!isAuthenticated ? '/sign-up' : MY_ACRE_ROUTE}
                  passHref
                >
                  <PrimaryButtonLink
                    onClick={
                      !isAuthenticated
                        ? () =>
                            track({
                              event: 'Sign Up Flow Opened',
                              properties: {
                                link_type: 'Button',
                                link_location: 'Nav',
                                link_text: primaryCta.text,
                              },
                            })
                        : () => null
                    }
                  >
                    {primaryCta.text}
                  </PrimaryButtonLink>
                </Link>
              ) : null}

              {isAuthenticated &&
              customerStatus === 'success' &&
              customerData ? (
                <AccountDropdown
                  profilePicture={customerData.picture}
                  onLogout={handleLogout}
                />
              ) : null}
            </div>
          </div>

          {/* Mobile Nav */}
          <div className="h-mobile-nav border-t desktop-nav:hidden">
            <ul
              className={cx('grid h-full w-full', {
                'grid-cols-4': isAuthenticated,
                'grid-cols-5': !isAuthenticated,
              })}
            >
              {isAuthenticated ? (
                <>
                  <MobileNavItem
                    text={MyAcreLink.text}
                    url={MyAcreLink.url}
                    icon={DashboardIcon}
                    trackingEvent={MyAcreLink.event}
                    trackingPriority={1}
                  />
                  <MobileNavItem
                    text={HomesLink.text}
                    url={HomesLink.url}
                    icon={HomesIcon}
                    trackingEvent={HomesLink.event}
                    trackingPriority={2}
                  />
                  <MobileNavItem
                    text={HowItWorksLink.text}
                    url={HowItWorksLink.url}
                    icon={NavbarHelpCircleIcon}
                    trackingEvent={HowItWorksLink.event}
                    trackingPriority={3}
                  />
                </>
              ) : (
                <>
                  <MobileNavItem
                    text={t('navbar.acre')}
                    url="/"
                    icon={NavAcreIcon}
                    iconClass="stroke-grey-7 [stroke-width:1.5px]"
                    iconSelectedClass="stroke-grey-8 [stroke-width:3px]"
                  />
                  <MobileNavItem
                    text={HomesLink.text}
                    url={HomesLink.url}
                    icon={HomesIcon}
                    trackingEvent={HomesLink.event}
                    trackingPriority={1}
                  />
                  <MobileNavItem
                    text={t('navbar.sign-up')}
                    url="/sign-up"
                    icon={UserPlusIcon}
                    trackingEvent="Sign Up Flow Opened"
                    trackingPriority={2}
                  />
                  <MobileNavItem
                    text={t('navbar.calculator')}
                    url="/calculator"
                    icon={CalculatorIcon}
                    trackingEvent={CalculatorLink.event}
                    trackingPriority={3}
                  />
                </>
              )}
              <li>
                <Dialog.Root
                  open={showMobileNav}
                  onOpenChange={(open) => setShowMobileNav(open)}
                >
                  <Dialog.Trigger className="grid h-full w-full place-items-center util-caption">
                    <div className="space-y-8">
                      <MenuIcon
                        className={cx('mx-auto stroke-grey-7 rect-24')}
                      />
                      <span className="block">{t('navbar.more')}</span>
                    </div>
                  </Dialog.Trigger>
                  <Dialog.Portal>
                    <Dialog.Overlay
                      className={cx('fixed inset-0 z-modal', {
                        'top-site-banner': siteBanner,
                      })}
                    >
                      <Dialog.Content
                        className={cx('fixed inset-0 bg-white', {
                          'top-site-banner': siteBanner,
                        })}
                      >
                        <Dialog.Title className="sr-only">
                          Site Navigation
                        </Dialog.Title>
                        <div className="flex h-full flex-col">
                          <div className="flex items-center justify-between px-24">
                            <div className="flex">
                              <Link href="/">
                                <a className="flex items-center py-18">
                                  <span className="sr-only">Acre</span>
                                  <AcreLogoIcon
                                    className="fill-tan-3 text-grey-8 rect-logo"
                                    aria-hidden="true"
                                  />
                                </a>
                              </Link>
                            </div>
                            <Dialog.Close>
                              <span className="sr-only">Close</span>
                              <CloseIcon
                                className="stroke-teal-5 rect-24"
                                aria-hidden="true"
                              />
                            </Dialog.Close>
                          </div>
                          <div className="grow overflow-auto">
                            <ul className="py-12">
                              {mobileLinks.map(({ text, url, event }, i) => (
                                <li key={text}>
                                  <Link href={url}>
                                    <a
                                      className="block px-32 py-20 text-[28px] font-semibold leading-none"
                                      onClick={() => {
                                        setShowMobileNav(false)
                                        track({
                                          event,
                                          properties: {
                                            link_type: 'Text',
                                            link_location: 'Nav',
                                            link_text: text,
                                            link_position: 'Dropdown',
                                            link_priority: i + 1,
                                          },
                                        })
                                      }}
                                    >
                                      {text}
                                    </a>
                                  </Link>
                                </li>
                              ))}
                              {isAuthenticated ? (
                                <>
                                  <li>
                                    <Link href="/account">
                                      <a
                                        className="block px-32 py-20 text-[28px] font-semibold leading-none"
                                        onClick={() => {
                                          track({
                                            event: 'Account Page Opened',
                                            properties: {
                                              link_type: 'Text',
                                              link_location: 'Nav',
                                              link_text: t('navbar.my-account'),
                                              link_position: 'Dropdown',
                                              link_priority:
                                                mobileLinks.length + 1,
                                            },
                                          })
                                        }}
                                      >
                                        {t('navbar.my-account')}
                                      </a>
                                    </Link>
                                  </li>
                                  <li className="mb-20">
                                    <Link href="/book" passHref>
                                      <a
                                        className="block px-32 py-20 text-[28px] font-semibold leading-none"
                                        onClick={() =>
                                          track({
                                            event: 'Book-a-Call Opened',
                                            properties: {
                                              link_type: 'Text',
                                              link_location: 'Nav',
                                              link_text:
                                                t('navbar.book-a-call'),
                                              link_position: 'Dropdown',
                                              link_priority:
                                                mobileLinks.length + 2,
                                            },
                                          })
                                        }
                                      >
                                        {t('navbar.book-a-call')}
                                      </a>
                                    </Link>
                                  </li>
                                </>
                              ) : (
                                <li className="mt-20 px-32">
                                  <Link href="/sign-in" passHref>
                                    <SecondaryButtonLink
                                      className="block text-center"
                                      onClick={() => {
                                        track({
                                          event: 'Sign-in Flow Opened',
                                          properties: {
                                            link_type: 'Button',
                                            link_location: 'Nav',
                                            link_text: t('signin'),
                                            link_position: 'Dropdown',
                                            link_priority:
                                              mobileLinks.length + 1,
                                          },
                                        })
                                      }}
                                    >
                                      {t('navbar.signin')}
                                    </SecondaryButtonLink>
                                  </Link>
                                </li>
                              )}

                              {!customerData?.approval.status &&
                              router.asPath !== MY_ACRE_ROUTE &&
                              primaryCta?.text &&
                              primaryCta?.url ? (
                                <li className="my-24 px-32">
                                  <Link href={MY_ACRE_ROUTE} passHref>
                                    <PrimaryButtonLink className="block text-center">
                                      {primaryCta.text}
                                    </PrimaryButtonLink>
                                  </Link>
                                </li>
                              ) : null}

                              {customerData?.approval.status &&
                              !(
                                customerData?.homeOffer?.status &&
                                ['closed', 'won', 'open'].includes(
                                  customerData?.homeOffer?.status
                                )
                              ) ? (
                                <li className="mb-24 px-32">
                                  <ActionButton
                                    className="justify-center text-center"
                                    customerApprovalStatus={
                                      customerData?.approval.status
                                    }
                                    customerOfferStatus={
                                      customerData?.homeOffer?.status
                                    }
                                    customerApprovedAmount={
                                      customerData?.approval.maxPurchasePrice ||
                                      0
                                    }
                                  />
                                </li>
                              ) : null}
                              {isAuthenticated ? (
                                <li className="px-32">
                                  <SecondaryButton
                                    className="w-full"
                                    onClick={handleLogout}
                                  >
                                    {t('navbar.signout')}
                                  </SecondaryButton>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </Dialog.Content>
                    </Dialog.Overlay>
                  </Dialog.Portal>
                </Dialog.Root>
              </li>
            </ul>
          </div>
        </div>
        <ToastNotification siteBannerShowing={!!siteBanner} />
      </nav>
    </>
  )
}

export default Navbar
